import Vue from 'vue'
import App from './App.vue'
import './index.css'
import './assets/tailwind.css'
import router from './router'
import store from './store'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueSimpleAccordion from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import VueVideoPlayer from 'vue-video-player'
// require videojs style
import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'

import VueMeta from 'vue-meta'

Vue.use(VueMeta)


Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)



Vue.use(VueSimpleAccordion, {
  navigation: false
});
Vue.config.productionTip = false
Vue.component('v-select', vSelect)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
