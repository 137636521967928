<template>
  <div id="app">
    <transition name="home">
      <router-view/>
    </transition>
  </div>
</template>

<style>
.home-enter-active, .home-leave-active { transition: opacity .5s; }
.home-enter, .home-leave-active { opacity: 0; }
</style>
<script>

export default {
  components: {},
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Watch Lola',
    // all titles will be injected into this template
  }
}
</script>