import Vue from 'vue'
import Vuex from 'vuex'
import api from "@/util/api";
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        film: [],
        loading: false,
        snackbar: {
            success: false
        }
    },
    mutations: {
        SETFILM(context, payload) {
            this.state.film = payload
        }
    },
    actions: {
        POSTFILM(context, payload) {
            this.state.loading = true
            axios.post(process.env.VUE_APP_API_ENDPOINT + 'project/video', payload, {
                headers: {
                    Authorization: 'Baerer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.state.loading = false
                context.dispatch('SNACKBAR')
                context.dispatch('GETFILM')
            })
                .catch(err => {
                    console.log(err)
                    this.state.loading = false
                })

        },
        POST(context, payload) {
            this.state.loading = true
            api.post('project', payload)
                .then(() => {
                    context.dispatch('SNACKBAR')
                    context.dispatch('GETFILM')
                    this.state.loading = false
                })
                .catch(err => {
                    console.log(err)
                    this.state.loading = false
                })
        },
        SNACKBAR() {
            this.state.snackbar.success = true
            setTimeout(() => {
                this.state.snackbar.success = false
            }, 5555)
        },
        UPDATEFILM(context, payload) {
            this.state.loading = true
            axios.put(process.env.VUE_APP_API_ENDPOINT + 'project/video', payload, {
                headers: {
                    Authorization: 'Baerer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.state.loading = false
                context.dispatch('SNACKBAR')
                context.dispatch('GETFILM')
            })
                .catch(err => {
                    console.log(err)
                    this.state.loading = false
                })
        },
        DELETE(context, payload) {
            this.state.loading = true
            api.delete('project/' + payload)
                .then(() => {
                    this.state.loading = false
                    context.dispatch('SNACKBAR')
                    context.dispatch('GETFILM')
                })
                .catch((err) => {
                    console.log(err)
                    this.state.loading = false
                })

        },
        GETFILM(ctx) {
            api.get('/projects?populate=*').then(async resp => {
                let payload = []
                await resp.data.data.forEach(data => {
                    payload.push(
                        {
                            category: data.attributes.Category,
                            description: data.attributes.Description,
                            file: data.attributes.Video.data.attributes.url,
                            menu: data.attributes.Menu,
                            name: data.attributes.Name,
                            thumbnail: data.attributes.Thumbnail.data.attributes.url
                        }
                    )
                })
                ctx.commit('SETFILM', payload)
            })
                .catch(e => {
                    console.log(e)
                })
        }

    },
    getters: {
        menuFilm: state => {
            return state.film.filter(x => x.menu === true)
        },
        category: (state) => (category) => {
            return state.film.filter(x => x.category === category)
        }
    },
    modules: {}
})
